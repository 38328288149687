import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingComponent } from './booking/booking.component';
import {OrderComponent} from './order/order.component';
import {SuccessfulComponent} from './successful/successful.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {GenerateTicketComponent} from './generate-ticket/generate-ticket.component';

const routes: Routes = [
  { path: '', component: BookingComponent },
  { path: 'order', component: OrderComponent },
  { path: 'successful', component: SuccessfulComponent },
  { path: 'terms', component: TermsAndConditionsComponent },
  { path: 'generateTicket', component: GenerateTicketComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
