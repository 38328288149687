import { Component, OnInit } from '@angular/core';
import {TicketsService} from '../tickets.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  bookedChairs = [];
  unavailableChairs = [];
  loaded = false;
  constructor(private ticketsService: TicketsService) { }

  ngOnInit() {
    this.ticketsService.getUnavailableChairs()
      .subscribe(data => {
        this.loaded = true;
        this.unavailableChairs = data as string[];
        setTimeout(() => {
          console.log(this.unavailableChairs[0]);
            for (const uChair of this.unavailableChairs) {
              document.getElementById(uChair).style.background = 'red';
            }
          },
          500);
      });
  }

  bookChair(event) {
    const chairToBook = event.target;
    if (!this.unavailableChairs.includes(chairToBook.id)) {
      if (this.bookedChairs.includes(chairToBook.id)) {
        chairToBook.style.background = '#34cf37';
        const index = this.bookedChairs.indexOf(chairToBook.id, 0);
        this.bookedChairs.splice(index, 1);
      } else {
        chairToBook.style.background = '#237afc';
        this.bookedChairs.push(chairToBook.id);
      }

      this.ticketsService.bookedChairs = this.bookedChairs;
    }
  }
}
