import { Component, OnInit } from '@angular/core';
import {TicketsService} from '../tickets.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Order} from '../order';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  infoFormGroup: FormGroup;

  constructor(private ticketsService: TicketsService, private router: Router, private formBuilder: FormBuilder) { }
  bookedChairs = [];
  phoneRegex = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$';

  ngOnInit() {
    this.bookedChairs = this.ticketsService.bookedChairs;
    if (this.bookedChairs.length < 1 || this.bookedChairs.length > 20) {
      this.router.navigate(['/']);
    }

    this.infoFormGroup = this.formBuilder.group({
      firstNameCtrl: ['', Validators.required],
      lastNameCtrl: ['', Validators.required],
      emailCtrl: ['', Validators.email],
      phoneCtrl: ['', Validators.pattern(this.phoneRegex)],
      termsCtrl: ['', Validators.requiredTrue]
    });
  }
  order() {
   if (this.infoFormGroup.valid) {
     const order: Order = {
       first_name: this.infoFormGroup.get('firstNameCtrl').value,
       last_name: this.infoFormGroup.get('lastNameCtrl').value,
       email: this.infoFormGroup.get('emailCtrl').value,
       phone_number: this.infoFormGroup.get('phoneCtrl').value,
       chairs: JSON.stringify(this.bookedChairs).toString()
     };
    this.ticketsService.placeOrder(order as Order).subscribe(data => {
        if (data.status === 201) {
          this.router.navigate(['/successful']);
        } else {
          alert(data.body);
        }
      }
    );
   }
  }
}
