import { Component, OnInit } from '@angular/core';
import {TicketsService} from '../tickets.service';

@Component({
  selector: 'app-generate-ticket',
  templateUrl: './generate-ticket.component.html',
  styleUrls: ['./generate-ticket.component.scss']
})
export class GenerateTicketComponent implements OnInit {

  constructor(private ticketsService: TicketsService) { }
  email = '';
  adminPass = '';
  response = '';

  ngOnInit() {
  }

  generateTicket() {
    this.ticketsService.generateTicket(this.email, this.adminPass).subscribe(data => {
      this.response = data.body.toString();
    });
  }
}
