import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Order} from './order';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  bookedChairs = [];
  constructor(private http: HttpClient) { }
  placeOrder(order: Order) {
    const url = 'https://api.pivniples.cz/order';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8', 'Accept': 'application/json;charset=utf-8'
    });
    return this.http.post(url, order, {headers: headers, observe: 'response', responseType: 'text' as 'json'});
  }
  getUnavailableChairs() {
    const url = 'https://api.pivniples.cz/getUnavailableChairs';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8', 'Accept': 'application/json;charset=utf-8'
    });
    return this.http.post(url, {headers: headers, observe: 'response', responseType: 'text' as 'json'});
  }
  generateTicket(email: string, password: string) {
    const url = 'https://api.pivniples.cz/generateTicket';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=utf8', 'Accept': 'application/json;charset=utf-8'
    });
    return this.http.post(url, {email: email, password: password}, {headers: headers, observe: 'response', responseType: 'text' as 'json'});
  }
}
